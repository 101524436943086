let lastSrcs;
const scriptReg = /\<script.*src=["'](?<src>[^"']+)/gm;
async function extractNewScripts() {
    const env=process.env.NODE_ENV
    var api='/'
    /* if(env=='development'){
        api='/'
    }else{
        api='/jzcustomerproject/dist/'
    } */
    const html = await fetch(api+'?_timestamp=' + Date.now()).then((resp) => resp.text())
    scriptReg.lastIndex = 0;
    let result = [];
    let match
    while ((match = scriptReg.exec(html))) {
        result.push(match.groups.src)
    }
    return result
}
async function needUpdate() {
    const newScripts = await extractNewScripts();
    if (!lastSrcs) {
        lastSrcs = newScripts
        return false;
    }
    let result = false;
    if (lastSrcs.length !== newScripts.length) {
        result = true
    }
    for (let i = 0; i < lastSrcs.length; i++) {
        if (lastSrcs[i] !== newScripts[i]) {
            result = true
            break
        }
    }
    lastSrcs=newScripts;
    return result
}
const DURATION=10000;
function autoRefresh(){
    setTimeout(async()=>{
        const willUpdate=await needUpdate()
        if(willUpdate){
            const result=confirm('The page has been updated. Click OK to refresh the page')
            if(result){
                location.reload()
            }
        }
        autoRefresh()
    },DURATION)
}
autoRefresh()