
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout 竖向*/
import Layout from '@/views/layout/Layout'
/* abeam 横向*/
import Abeam from '@/views/abeam/Layout'


export const routerMap = {
  Layout: () => Layout,

}
export const constantRouterMap = [
  //首次加载时先走登录页
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import("@/views/login/index")
    }
  },

  //路由重定向
  {
    path: '/',
    redirect: "/login"
  },
  /* {
    path: '/',
    redirect: "/login"
  }, */
  // 没有下拉菜单，页面卸载children中
  {
    path: '/homeView',
    component: Abeam,
    redirect: 'home',
    name: 'homeView',
    alwaysShow: true,
    meta: {
      title: 'Home',//标题
      title1: '首页',
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home',
        component: () => import('@/views/pages/home'),
        name: 'home',
        meta: { title: 'Home',title1: '首页', }
      },
    ]
  },
  {
    path: '/browseView',
    component: Layout,
    redirect: 'Function',
    name: 'browseView',
    alwaysShow: true,
    meta: {
      title: 'Browse',//标题
      title1: '查询',
      icon: 'search'//图标
    },
    children: [
      {
        path: '/Function',
        component: () => import('@/views/pages/search'),
        name: 'Function',
        meta: { title: 'Functional Annotation', title1: '功能注释',icon:'chazhao'}
      },{
        path: '/pathway',
        component: () => import('@/views/pages/pathway'),
        name: 'pathway',
        meta: { title: 'Pathway Infomation', title1: '通路信息',icon: 'pathway'}
      },{
        path: '/Transposable-Elements',
        component: () => import('@/views/pages/TE'),
        name: 'Transposable Elements',
        meta: { title: 'Transposable Elements',title1: '转位因子', icon: 'te'}
      }
    ]
  },
  {
    path: '/GenomesView',
    component: Layout,
    redirect: 'Genomes',
    name: 'GenomesView',
    alwaysShow: true,
    meta: {
      title: 'Genomes',//标题
      title1: '基因组学',//标题
      icon: 'msa'//图标
    },
    children: [
      {
        path: '/Genomes/genome1',
        component: () => import('@/views/pages/Genomes'),
        name: 'genome1',
        meta: { title: 'genome1',title1: 'genome1',icon:'genome1'}
      },
      {
        path: '/Genomes/genome2',
        component: () => import('@/views/pages/Genomes'),
        name: 'genome2',
        meta: { title: 'genome2',title1: 'genome2',icon:'genome1'}
      },
      {
        path: '/Genomes/genome3',
        component: () => import('@/views/pages/Genomes'),
        name: 'genome3',
        meta: { title: 'genome3',title1: 'genome3',icon:'genome1'}
      },
      /* {
        path: '/Genomes',
        component: () => import('@/views/pages/Genomes'),
        redirect:"/Genomes/genome1",
        name: 'Genomes',
        meta: { title: 'Genomes', title1: '基因组学',}
      }, */
    ]
  },
/*   {
    path: '/JbrowseView',
    component: Layout,
    redirect: 'Jbrowse',
    name: 'JbrowseView',
    alwaysShow: true,
    meta: {
      title: 'Jbrowse',//标题
      icon: 'browse'//图标
    },
    children: [
      {
        path: '/Jbrowse',
        component: () => import('@/views/pages/Jbrowse'),
        name: 'Jbrowse',
        meta: { title: 'Jbrowse', }
      },
    ]
  }, */
  {
    path: '/VariationView',
    component: Layout,
    redirect: 'Variation',
    name: 'VariationView',
    alwaysShow: true,
    meta: {
      title: 'Variation',//标题
      title1: '变异',//标题
      icon: 'fst'//图标
    },
    children: [
      {
        path: '/SNP-Seek',
        component: () => import('@/views/pages/SNP-Seek'),
        name: 'SNP-Seek',
        meta: { title: 'SNP-Seek', title1: '恒久性变异',icon: 'search1'}
      },
      {
        path: '/Ex-Seq',
        component: () => import('@/views/pages/Ex-Seq'),
        name: 'Ex-Seq',
        meta: { title: 'Ex-Seq', title1: '序列提取',icon: 'gwas1'}
      },
      {
        path: '/VariationsSearch',
        component: () => import('@/views/pages/VariationsSearch'),
        name: 'VariationsSearch',
        meta: { title: 'Variations Search', title1: '变异查询',icon: 'go'}
      },
    ]
  },
  {
    path: '/SyntenicView',
    component: Layout,
    redirect: 'Syntenic',
    name: 'SyntenicView',
    alwaysShow: true,
    meta: {
      title: 'Syntenic',//标题
      title1: '关联性查询',
      icon: 'lianjie'//图标
    },
    children: [
        {
          path: '/Syntenic/0',
          component: () => import('@/views/pages/Syntenic'),
          name: 'genome1 VS genome2',
          meta: { title: 'genome1 VS genome2',title1: 'genome1 VS genome2',icon:'genome'}
        },
        {
          path: '/Syntenic/1',
          component: () => import('@/views/pages/Syntenic'),
          name: 'genome1 VS genome3',
          meta: { title: 'genome1 VS genome3',title1: 'genome1 VS genome3',icon:'genome'}
        },
        {
          path: '/Syntenic/2',
          component: () => import('@/views/pages/Syntenic'),
          name: 'genome2 VS genome3',
          meta: { title: 'genome2 VS genome3',title1: 'genome2 VS genome3',icon:'genome'}
        },
      /* {
        path: '/Syntenic',
        component: () => import('@/views/pages/Syntenic'),
        redirect:"/Syntenic/0",
        name: 'Syntenic',
        meta: { title: 'Syntenic',title1: '关联性查询', icon: 'search1'},
        
      }, */
    ]
  },
  {
    path: '/toolsView',
    component: Layout,
    redirect: 'tools',
    name: 'toolsView',
    alwaysShow: true,
    meta: {
      title: 'Tools',//标题
      title1: '工具',
      icon: 'tools'//图标
    },
    children: [
      {
        path: '/Blast',
        component: () => import('@/views/pages/Blast'),
        name: 'Blast',
        meta: { title: 'Blast', title1: '序列比对', icon:'blast'}
      },
      {
        path: '/Heatmap',
        component: () => import('@/views/pages/heatmap'),
        name: 'Heatmap',
        meta: { title: 'Heatmap', title1: '热图',icon:'gwas1'}
      },
      {
        path: '/MSA',
        component: () => import('@/views/pages/MSA'),
        name: 'MSA',
        meta: { title: 'MSA',  title1: '多序列比对',icon:'msa'}
      },
      {
        path: '/KEGG',
        component: () => import('@/views/pages/KEGG'),
        name: 'KEGG',
        meta: { title: 'KEGG Enrichment',  title1: 'KEGG富集',icon:'jiyinxin'}
      },
      {
        path: '/GO',
        component: () => import('@/views/pages/GO'),
        name: 'GO',
        meta: { title: 'GO Enrichment', title1: 'GO富集', icon:'kegg'}
      },
      {
        path: '/OrthologousGene',
        component: () => import('@/views/pages/OrthologousGene'),
        name: 'OrthologousGene',
        meta: { title: 'Orthologous Gene', title1: '同源基因', icon:'go'}
      },{
        path: '/Jbrowse',
        component: () => import('@/views/pages/Jbrowse'),
        name: 'Jbrowse',
        meta: { title: 'Jbrowse', title1: '基因组浏览器',icon:'browse'}
      }
    ]
  },
  
  {
    path: '/downloadView',
    component: Layout,
    redirect: 'download',
    name: 'downloadView',
    alwaysShow: true,
    meta: {
      title: 'Download',//标题
      title1: '下载',//标题
      icon: 'download'//图标
    },
    children: [
      {
        path: '/download',
        component: () => import('@/views/pages/download'),
        name: 'download',
        meta: { title: 'Download', title1: '下载',}
      },
    ]
  },
  {
    path: '/ContactView',
    component: Layout,
    redirect: 'Contact',
    name: 'ContactView',
    alwaysShow: true,
    meta: {
      title: 'Contact',//标题
      title1: '联系我们',
      icon: 'contact'//图标
    },
    children: [
      {
        path: '/Contact',
        component: () => import('@/views/pages/Contact'),
        name: 'Contact',
        meta: { title: 'Contact',title1: '联系我们', }
      },
    ]
  },
  {
    path: '/detail',
    component: Layout,
    redirect: 'detail',
    name: 'detail',
    alwaysShow: false,
    meta: {
      title: 'detail',//标题
      title1: '详情页',//标题
      icon: 'home',
    },
    children: [
      {
        path: '/detail',
        component: () => import('@/views/pages/detail'),
        name: 'detail',
        meta: { title: 'detail',title1: '详情页',}
      }
    ]
  },
  // 有下拉菜单，写在children中
 /*  {
    path: '/home1',
    component: Layout,
    // redirect: 'area',
    name: 'anti_channel',
    alwaysShow: true,
    meta: {
      title: '其他页面',//标题
      icon: 'home'//图标
    },
    children: [
      {
        path: '/home1/mm',
        component: () => import('@/views/login/index'),
        name: 'mm',
        meta: { title: '测试2', }
      },
      {
        path: '/home1/ceshi',
        component: () => import('@/views/login/index'),
        name: 'ceshi',
        meta: { title: '测试3', }
      },
    ]
  } */
 
  // {
  //	 path: '/404',
  //	 component: () => import('@/views/errorPage/404'),
  //	 hidden: true
  // },
  // {
  //	 path: '/401',
  //	 component: () => import('@/views/errorPage/401'),
  //	 hidden: true
  // }
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  /** When your routing table is too long, you can split it into small modules**/
]

