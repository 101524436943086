
  <template>
  <div class="app-wrapper">
    <!-- :class="classObj" -->
    <!-- <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    /> -->
    <el-container style="height: 100vh">
      <!--  <el-aside :class="isCollapse?'closeSidebar':'openSidebar'" class="aside">
        <sidebar class="sidebar-container" :isCollapse="isCollapse"/>
      </el-aside>
      <el-container> -->
        <!-- :class="top <= 500 ? 'headerone' : 'headertwo'" -->
      <el-header class="header">
        <div class="back"></div>
        <navbar
          @isCollapseFunction="isCollapseFunction"
          :isCollapse="isCollapse"
          :color="change?'rgb(15 15 15)':'#f8f3f3'"
        />
       
        <!--<tags-view /> -->
      </el-header>
      <el-main class="app-main">
        <app-main @ChangeColor="ChangeColor"/> 
        <!--  -->
        <el-backtop target=".app-main" :visibility-height="100"></el-backtop
        >
        <!-- <el-footer class="footer">©{{ year }} Smart Genomics Co., Ltd. All Rights Reserved</el-footer> -->
      </el-main>
      <!--  </el-container> -->
    </el-container>
    
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain /* TagsView */ } from "./components";
export default {
  name: "layout",
  props: {
    //传值
  },
  data() {
    return {
      isCollapse: false,
      top: 1,
      year:'',
      back:"浅色",
      change:false
    };
  },
  components: {
    //引入模块
    Navbar,
    Sidebar,
    AppMain,
    /* TagsView */
  },
  watch: {
    // watch擅长处理的场景：一个数据影响多个数据
  },
  computed: {
    // computed擅长处理的场景：一个数据受多个数据影响
  },
  mounted: function () {
    // 编译好的HTML挂载到页面完成后执行的事件钩子
    // el 被新创建的 vm.el 替换，并挂载到实例上去之后调用该钩子。
    // 此钩子函数中一般会做一些ajax请求获取数据进行数据初始化
    console.log("Home");
    window.addEventListener("scroll", this.handleScroll, true);
    var year = this.getDateTime("year");
    this.year = year;
  },
  methods: {
    // 组件的方法
    
    ChangeColor(e){
      // console.log(e,'layout')
      // this.back=e
      this.change=e
    },
    isCollapseFunction(boolean) {
      this.isCollapse = boolean;
    },
   /*  handleScroll(e) {
      var top = e.target.scrollTop;
      this.top = top;
    }, */
    getDateTime(type) {
      var date = new Date();
      var hengGang = "-";
      var maoHao = ":";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var curDate = date.getDate();
      var curHours = date.getHours();
      var curMinutes = date.getMinutes();
      var curSeconds = date.getSeconds();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (curDate >= 0 && curDate <= 9) {
        curDate = "0" + curDate;
      }
      if (curHours >= 0 && curHours <= 9) {
        curHours = "0" + curHours;
      }
      if (curMinutes >= 0 && curMinutes <= 9) {
        curMinutes = "0" + curMinutes;
      }
      if (curSeconds >= 0 && curSeconds <= 9) {
        curSeconds = "0" + curSeconds;
      }
      var currentdate = "";
      if (type == "year") {
        currentdate = year;
        return currentdate;
      } else if (type == "month") {
        currentdate = year + hengGang + month;
        return currentdate;
      } else {
        currentdate =
          year +
          hengGang +
          month +
          hengGang +
          curDate +
          " " +
          curHours +
          maoHao +
          curMinutes +
          maoHao +
          curSeconds;
        return currentdate;
      }
    },
  },
  beforeCreate: function () {
    // 在实例初始化之后，数据观测(data observer) 和 event/watcher 事件配置之前被调用。
  },
  created: function () {
    // 实例已经创建完成之后被调用。在这一步，实例已完成以下的配置：数据观测(data observer)，属性和方法的运算， watch/event 事件回调。然而，挂载阶段还没开始，el 属性目前不可见。
  },
  beforeMount: function () {
    // 在挂载开始之前被调用：相关的 render 函数首次被调用。
  },
  beforeUpdate: function () {
    // 数据更新时调用，发生在虚拟 DOM 重新渲染和打补丁之前。 你可以在这个钩子中进一步地更改状态，这不会触发附加的重渲染过程。
  },
  updated: function () {
    // 由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。
    // 当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。然而在大多数情况下，你应该避免在此期间更改状态，因为这可能会导致更新无限循环。
    // 该钩子在服务器端渲染期间不被调用。
  },
  beforeDestroy: function () {
    // 实例销毁之前调用。在这一步，实例仍然完全可用。
  },
  destroyed: function () {
    // Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 该钩子在服务器端渲染期间不被调用。
  },
};
</script>
<style scoped>
.header {
  height: 70px !important;
  padding: 0 !important;
  /* position: fixed; */
  /* z-index: 10000; */
}
/* .headerone {
  color: rgba(255, 255, 255, 1);
  box-shadow: none;
  transition: 0.4s background-color, color 0.4s;
} */
/* .headertwo {
  color: #000;
  box-shadow: none;
  transition: 0.4s background-color, color 0.4s;
  background: transparent;
} */
.aside {
  background-color: rgb(238, 241, 246);
}
.openSidebar {
  width: 200px !important;
}
.closeSidebar {
  width: 65px !important;
}
.app-main {
  padding: 0;
  overflow: inherit;
}

iframe{
  width: 500px;
  height: 200px;
  /* margin: 0 auto; */
    /* width: 100%; */
  /* margin-left: -20px; */
  pointer-events: none;
}
.footer{
  /* display: flex; */
  /* justify-content: center; */
  width: 100%;
  /* height: 100px !important; */
  /* background: rgba(8, 138, 105, 0.8); */
  /* margin-top: 40px !important; */
  /* margin: 0 auto; */
  /* align-items: center; */
  /* position: relative; */
  background: #eee;
  text-align: center;

}
.back{
  width: 100%;
  height: 100%;
  z-index: 0;
  position: fixed;
  object-fit: cover;
  background-image: url("@/assets/back2.png");
  background-repeat: no-repeat;
    background-size: cover;
  /* background: rgb(55,59,68); */
  /* background: linear-gradient(90deg, rgba(55,59,68,1) 0%, rgba(66,134,244,1) 100%); */
}

</style>